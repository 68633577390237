import { relations } from "drizzle-orm";
import {
  boolean,
  index,
  integer,
  jsonb,
  pgEnum,
  text,
  timestamp,
  varchar,
} from "drizzle-orm/pg-core";

import { type Word2TaskData } from "~/server/zod/word2";

import { pgTable, pgTypeId } from "../typeid";
import { users } from "./auth";
import { groupCategories } from "./groupCategories";
import { groups } from "./groups";

export const taskTypes = [
  "WORD",
  "WORD2",
  "EXIT",
  "AICHAT",
  "DICTATION",
  "AIGRADER",
  "ICEBREAKER",
  "CLOZE",
  "EXAM",
  "SCATTERGORIES",
] as const;
export const taskPgEnum = pgEnum("task_enum", taskTypes);

export const tasks = pgTable(
  "tasks",
  {
    id: pgTypeId("id").primaryKey(),
    name: varchar("name", { length: 256 }).notNull(),
    subject: varchar("subject").notNull(),
    type: taskPgEnum("type").notNull(),
    removed: boolean("removed").notNull().default(false),
    createdByUserId: varchar("created_by_user_id")
      .notNull()
      .references(() => users.id, {
        onDelete: "cascade",
      }),
    createdAt: timestamp("created_at").defaultNow().notNull(),
    updatedAt: timestamp("updated_at").defaultNow(),
  },
  (table) => {
    return {
      removedIdx: index("removed_idx").on(table.removed),
    };
  },
);

export const taskId = pgTypeId("task_id")
  .primaryKey()
  .references(() => tasks.id, {
    onDelete: "cascade",
  });

export const publishedAccessEnum = pgEnum("published_access_enum", [
  "PUBLIC",
  "WITH_LINK",
]);

export const publishedTasks = pgTable(
  "published_tasks",
  {
    taskId,
    duplicateCount: integer("duplicate_count").notNull().default(0),
    instanceCount: integer("instance_count").notNull().default(0),
    showName: boolean("show_name").notNull().default(true),
    showSchool: boolean("show_school").notNull().default(true),
    access: publishedAccessEnum("access").notNull().default("PUBLIC"),
    createdAt: timestamp("created_at").defaultNow().notNull(),
    updatedAt: timestamp("updated_at").defaultNow(),
  },
  (table) => {
    return {
      accessIdx: index("access_idx").on(table.access),
    };
  },
);

export const publishedTasksRelations = relations(publishedTasks, ({ one }) => ({
  task: one(tasks, {
    fields: [publishedTasks.taskId],
    references: [tasks.id],
  }),
}));

export const wordTasks = pgTable("word_tasks", {
  taskId,
  createdAt: timestamp("created_at").defaultNow().notNull(),
  updatedAt: timestamp("updated_at").defaultNow(),
  leftTitle: varchar("left_title", { length: 256 }).notNull(),
  rightTitle: varchar("right_title", { length: 256 }).notNull(),
});

export const wordTaskWords = pgTable("word_task_words", {
  id: pgTypeId("id").primaryKey(),
  wordTaskId: pgTypeId("word_task_id").references(() => wordTasks.taskId, {
    onDelete: "cascade",
  }),
  left: varchar("left", { length: 256 }).notNull(),
  right: varchar("right", { length: 256 }).notNull(),
  createdAt: timestamp("created_at").defaultNow().notNull(),
  updatedAt: timestamp("updated_at").defaultNow(),
});

export const wordTasksRelations = relations(wordTasks, ({ many }) => ({
  words: many(wordTaskWords),
}));

export const wordTaskWordsRelations = relations(wordTaskWords, ({ one }) => ({
  wordTask: one(wordTasks, {
    fields: [wordTaskWords.wordTaskId],
    references: [wordTasks.taskId],
  }),
}));

export const exitTasks = pgTable("exit_tasks", {
  taskId,
  createdAt: timestamp("created_at").defaultNow().notNull(),
  updatedAt: timestamp("updated_at").defaultNow(),
});

export const EXIT_TASK_QUESTION_MAX_LENGTH = 1024;

export const exitTaskQuestions = pgTable("exit_task_questions", {
  id: pgTypeId("id").primaryKey(),
  exitTaskId: pgTypeId("exit_task_id").references(() => exitTasks.taskId, {
    onDelete: "cascade",
  }),
  question: varchar("question", {
    length: EXIT_TASK_QUESTION_MAX_LENGTH,
  }).notNull(),
  createdAt: timestamp("created_at").defaultNow().notNull(),
  updatedAt: timestamp("updated_at").defaultNow(),
});

export const exitTasksRelations = relations(exitTasks, ({ many }) => ({
  questions: many(exitTaskQuestions),
}));

export const exitTaskQuestionsRelations = relations(
  exitTaskQuestions,
  ({ one }) => ({
    exitTask: one(exitTasks, {
      fields: [exitTaskQuestions.exitTaskId],
      references: [exitTasks.taskId],
    }),
  }),
);

export const icebreakerTasks = pgTable("icebreaker_tasks", {
  taskId,
  createdAt: timestamp("created_at").defaultNow().notNull(),
  updatedAt: timestamp("updated_at").defaultNow(),
});

export const icebreakerTaskQuestions = pgTable("icebreaker_task_questions", {
  id: pgTypeId("id").primaryKey(),
  icebreakerTaskId: pgTypeId("icebreaker_task_id").references(
    () => icebreakerTasks.taskId,
    {
      onDelete: "cascade",
    },
  ),
  question: varchar("question", { length: 256 }).notNull(),
  createdAt: timestamp("created_at").defaultNow().notNull(),
  updatedAt: timestamp("updated_at").defaultNow(),
});

export const icebreakerTasksRelations = relations(
  icebreakerTasks,
  ({ many }) => ({
    questions: many(icebreakerTaskQuestions),
  }),
);

export const icebreakerTaskQuestionsRelations = relations(
  icebreakerTaskQuestions,
  ({ one }) => ({
    icebreakerTask: one(icebreakerTasks, {
      fields: [icebreakerTaskQuestions.icebreakerTaskId],
      references: [icebreakerTasks.taskId],
    }),
  }),
);

export const aiChatTasks = pgTable("ai_chat_tasks", {
  taskId,
  promptId: text("prompt").notNull(),
  teacherExtraPrompt: text("teacher_extra_prompt").notNull().default(""),
  createdAt: timestamp("created_at").defaultNow().notNull(),
  updatedAt: timestamp("updated_at").defaultNow(),
});

export const anonymizeModesEnum = pgEnum("anonymize_mode", [
  "full",
  "last_name_only",
  "none",
]);

export const aiGraderTasks = pgTable("ai_grader_tasks", {
  taskId,
  taskText: text("task_text").notNull(),
  taskTextRawLexical: jsonb("task_text_raw_lexical"),
  promptId: varchar("prompt_id").notNull(),
  includeStudentInstructions: boolean("include_student_instructions")
    .notNull()
    .default(false),
  wordLimit: integer("word_limit").notNull().default(0),
  hardWordLimit: boolean("hard_word_limit").notNull().default(false),
  anonymizeMode: anonymizeModesEnum("anonymize_mode").notNull().default("none"),
  createdAt: timestamp("created_at").defaultNow().notNull(),
  updatedAt: timestamp("updated_at").defaultNow(),
});

export const aiGraderFeedback = pgTable("ai_grader_feedback", {
  id: pgTypeId("id").primaryKey(),
  taskResultId: pgTypeId("task_result_id").notNull(),
  userId: varchar("user_id")
    .references(() => users.id, {
      onDelete: "cascade",
    })
    .notNull(),
  score: integer("score").notNull(),
  comment: text("comment"),
  createdAt: timestamp("created_at").defaultNow().notNull(),
  updatedAt: timestamp("updated_at").defaultNow(),
});

export const clozeTasks = pgTable("cloze_tasks", {
  taskId,
  rawLexical: jsonb("raw_lexical").notNull(),
  createdAt: timestamp("created_at").defaultNow().notNull(),
  updatedAt: timestamp("updated_at").defaultNow(),
});

export const examTasks = pgTable("exam_tasks", {
  taskId,
  rawLexical: jsonb("raw_lexical").notNull(),
  createdAt: timestamp("created_at").defaultNow().notNull(),
  updatedAt: timestamp("updated_at").defaultNow(),
});

export const dictationTasks = pgTable("dictation_tasks", {
  taskId,
  text: text("text").notNull(),
  audioClipId: varchar("audio_clip_id").notNull(),
  studentCanPlayAudio: boolean("student_can_play_audio").notNull(),
  createdAt: timestamp("created_at").defaultNow().notNull(),
  updatedAt: timestamp("updated_at").defaultNow(),
});

export const taskInstances = pgTable("task_instances", {
  id: pgTypeId("id").primaryKey(),
  taskId: pgTypeId("task_id")
    .notNull()
    .references(() => tasks.id, {
      onDelete: "cascade",
    }),
  groupId: integer("group_id")
    .notNull()
    .references(() => groups.id, {
      onDelete: "cascade",
    }),
  sortIndex: integer("sort_index").notNull().default(0),
  groupCategoryId: pgTypeId("group_category_id").references(
    () => groupCategories.id,
    {
      onDelete: "set default",
    },
  ),
  removed: boolean("removed").notNull().default(false),
  isFocused: boolean("is_focused").notNull().default(false),
  password: varchar("password", { length: 256 }),
  settings: jsonb("settings").notNull().default({}),
  createdAt: timestamp("created_at").defaultNow().notNull(),
  updatedAt: timestamp("updated_at").defaultNow(),
});

export const taskResults = pgTable("task_results", {
  id: varchar("id").primaryKey(),
  taskInstanceId: pgTypeId("task_instance_id")
    .references(() => taskInstances.id, {
      onDelete: "no action",
    })
    .notNull(),
  userId: varchar("user_id")
    .references(() => users.id, {
      onDelete: "no action",
    })
    .notNull(),
  groupId: integer("group_id")
    .notNull()
    .references(() => groups.id, {
      onDelete: "no action",
    }),
  summary: jsonb("summary").notNull(),
  createdAt: timestamp("created_at").defaultNow().notNull(),
  updatedAt: timestamp("updated_at").defaultNow(),
});

export const taskResultsRelations = relations(taskResults, ({ one }) => ({
  taskInstance: one(taskInstances, {
    fields: [taskResults.taskInstanceId],
    references: [taskInstances.id],
  }),
  user: one(users, {
    fields: [taskResults.userId],
    references: [users.id],
  }),
  aiGraderFeedback: one(aiGraderFeedback, {
    fields: [taskResults.id],
    references: [aiGraderFeedback.taskResultId],
  }),
}));

export const taskResultDiffs = pgTable("task_result_diffs", {
  id: varchar("id").primaryKey(),
  taskResultId: varchar("task_result_id")
    .references(() => taskResults.id, {
      onDelete: "no action",
    })
    .notNull(),
  userId: varchar("user_id")
    .references(() => users.id, {
      onDelete: "cascade",
    })
    .notNull(),
  old: jsonb("old").notNull(),
  new: jsonb("new").notNull(),
  createdAt: timestamp("created_at").defaultNow().notNull(),
  updatedAt: timestamp("updated_at").defaultNow(),
});

export const taskResultDiffRealtions = relations(
  taskResultDiffs,
  ({ one }) => ({
    taskResult: one(taskResults, {
      fields: [taskResultDiffs.taskResultId],
      references: [taskResults.id],
    }),
    user: one(users, {
      fields: [taskResultDiffs.userId],
      references: [users.id],
    }),
  }),
);

export const taskDuplicationLogs = pgTable(
  "task_duplication_logs",
  {
    id: pgTypeId("id").primaryKey(),
    taskId: pgTypeId("task_id")
      .references(() => tasks.id, {
        onDelete: "no action",
      })
      .notNull(),
    originalUserId: varchar("original_user_id")
      .references(() => users.id, {
        onDelete: "no action",
      })
      .notNull(),
    duplicatorUserId: varchar("duplicator_user_id")
      .references(() => users.id, {
        onDelete: "no action",
      })
      .notNull(),
    createdAt: timestamp("created_at").defaultNow().notNull(),
  },
  (table) => {
    return {
      taskIdIdx: index("task_id_idx").on(table.taskId),
      originalUserIdIdx: index("original_user_id_idx").on(table.originalUserId),
      duplicatorUserIdIdx: index("duplicator_user_id_idx").on(
        table.duplicatorUserId,
      ),
    };
  },
);

export const taskDuplicationLogsRelations = relations(
  taskDuplicationLogs,
  ({ one }) => ({
    task: one(tasks, {
      fields: [taskDuplicationLogs.taskId],
      references: [tasks.id],
    }),
    originalUser: one(users, {
      fields: [taskDuplicationLogs.originalUserId],
      references: [users.id],
    }),
    duplicatorUser: one(users, {
      fields: [taskDuplicationLogs.duplicatorUserId],
      references: [users.id],
    }),
  }),
);

export const word2Tasks = pgTable("word2_tasks", {
  taskId,
  data: jsonb("data").notNull().$type<Word2TaskData>(),
  createdAt: timestamp("created_at").defaultNow().notNull(),
  updatedAt: timestamp("updated_at").defaultNow(),
});
